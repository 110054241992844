<template>
  <div class="app-container">
    <div id="app-contain">
    <div class="filter-container" id="filter-container">
      <!-- <el-input v-model="listQuery.name" placeholder="任务名称" style="width: 200px;" class="filter-item" clearable />
      <el-input v-model="listQuery.deptName"  placeholder="单位名称" style="width: 200px;" class="filter-item" clearable />
      <el-date-picker v-model="listQuery.stateTime" type="datetime" style="width: 200px;" class="filter-item" clearable  placeholder="开始时间"/>
      <el-date-picker v-model="listQuery.endTime" type="datetime" style="width: 200px;" class="filter-item" clearable  placeholder="截止时间"/> -->


    <el-date-picker  v-model="listQuery.strStYear"  type="year" class="filter-item"  value-format="yyyy-MM-dd HH:mm:ss" :clearable="false"  placeholder="选择任务年度"> 
              </el-date-picker>
      <el-input v-model="listQuery.deptName"  placeholder="单位名称" style="width: 200px;" class="filter-item" clearable />
       <el-select v-model="listQuery.schoolType" placeholder="学校类型" style="width: 200px;" class="filter-item" clearable>
    <el-option
      v-for="item in schoolTypes"
      :key="item.code"
      :label="item.name"
      :value="item.code"
      >
    </el-option>
  </el-select>
      <el-button class="filter-item" type="primary" icon="el-icon-search" @click="loadList">
        查询
      </el-button>
    </div>
    <el-table
      v-loading="listLoading"
      :data="list"
      :height="tabheight"
      border
      fit
      highlight-current-row
      style="width: 100%;overflow:auto"
    >
      <el-table-column label="序号" type="index" :index="indexMethod" align="center" width="50x" />
      <el-table-column label="任务名称" header-align="center" align="left" prop="name"  min-width="250" />
      <el-table-column label="年度" header-align="center" align="left" prop="stYear" width="60" />
       <el-table-column label="任务状态" header-align="center" align="left" width="140">
          <template slot-scope="scope">
          <span>{{ getDicName(scope.row.state,'YW_TASK_SCHOOL_STATE') }}</span>
          </template>
        </el-table-column>  
       <el-table-column label="学校类型" header-align="center" align="left" width="140">
          <template slot-scope="scope">
          <span>{{ getDicName(scope.row.schoolType,'TXPE_OF_CAMOUS') }}</span>
          </template>
        </el-table-column>  
      <el-table-column label="填报单位" header-align="center" align="left" prop="departmentName" min-width="250" />
      <el-table-column label="提交时间" header-align="center" align="right" prop="modifyTime" width="200" show-overflow-tooltip />
      <el-table-column label="提交人" header-align="center" align="left" prop="modifyUser" min-width="150" />
      <el-table-column
        fixed="right"
        label="操作"
        width="200"
        header-align="center"
        align="left"
      >
        <template slot-scope="scope">
          <span>
            <el-button  type="primary" size="small" @click="handleEdit(scope.row)">审批</el-button>
            <el-button  type="primary" size="small" @click="handleSubmitTest(scope.row)">查看</el-button>
          </span>
        </template>
      </el-table-column>

    </el-table>
    <pagination id="pagination" v-show="total>0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="loadList" />
     <el-dialog :close-on-click-modal="false" :visible.sync="dialogVisibleTask" width="90%" append-to-body :title="'填报详情'">
        <task-index v-if="dialogVisibleTask" ref="taskindex" :proid="code" :visible.sync="dialogVisibleTask" />
      </el-dialog>
       <el-dialog :close-on-click-modal="false" :visible.sync="dialogVisibleEI" width="90%" append-to-body :title="'填报审批'">
        <examine-index v-if="dialogVisibleEI" ref="examineindex" :proid="code" :visible.sync="dialogVisibleEI" />
      </el-dialog>
    </div>
  </div>
</template>

<script>
// import { page, submitTest, release, revoke ,getDeployTreeList} from '@/api/api-deploy'
import { getDictListByCode } from '@/api/dict'
import Pagination from '@/components/Pagination'
import { tableHeight2 } from '@/utils/tableHeight'
import { districtPage } from '@/api/taskSchool'
import { Message, MessageBox } from 'element-ui'
// import {getLogByDevelopment} from "@/api/deployLog"
import TaskIndex from '../pending/dataFilling/index.vue'
import ExamineIndex from '../pending/dataFilling/examineIndex.vue'
export default {
  components: { Pagination,TaskIndex,ExamineIndex },
  mixins: [tableHeight2],
   provide() {
    return {
      onloadList: this.loadList
    }
  },
  data() {
    return {
      filterText:null,
      list: null,
      total: 0,
      listLoading: true,
      treeLoading:false,
      dialogVisibleLog:false,
      listQuery: {
        page: 1,
        limit: 20,
        name: null,
        type: null,
        itemId: this.$getCookie('projectId')
      },
      
      logQuery:{
        limit:10,
        page:1,
        id:null
      },
      apiTypes: [],
      treeList:[],
      schoolTypes:[],
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      tabheight:0,
      logList:[],
      apiDeployStates: [],
      dialogVisibleAdd: false,
      dialogVisibleTask:false,
      dialogVisibleEI:false,
      titel: '',
      form: {},
      rules: {
        itemId: [
          { required: true, message: '不能为空', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val)
    }
  },
   mounted(){
     this.$nextTick(function() {
      var filterHeight = document.getElementById('filter-container').offsetHeight
      var tableHeight = document.getElementById('app-contain').offsetHeight
      var pagination = document.getElementById('pagination').offsetHeight
      this.tabheight = tableHeight - filterHeight - pagination-15
      console.log(this.tabheight,filterHeight,tableHeight,pagination)
      window.onresize = () => {
        return (() => {
          var filterHeight = document.getElementById('filter-container').offsetHeight
          var tableHeight = document.getElementById('app-contain').offsetHeight
          var pagination = document.getElementById('pagination').offsetHeight
          this.tabheight = tableHeight - filterHeight - pagination-15
        })()
      }
    })
  },
  created() {
    this.loadList()
   // this.getTableTreeList();
    this.getDictList('YW_TASK_SCHOOL_STATE')
    this.getDictList('TXPE_OF_CAMOUS')
  },
  methods: {
    indexMethod(index) {
      return (index + 1) + (this.listQuery.page - 1) * this.listQuery.limit
    },
    getDictList(code) {
      getDictListByCode(code).then(response => {
        if (code === 'YW_TASK_SCHOOL_STATE') {
          this.apiTypes = response.data
        } else if (code === 'YW_TASK_SCHOOL_STATE') {
          this.apiDeployStates = response.data
        } else if (code === 'TXPE_OF_CAMOUS') {
          this.schoolTypes = response.data
        }
      })
    },
    getDicName(code, flag) {
      var dict = []
      if (flag === 'YW_TASK_SCHOOL_STATE') {
        dict = this.apiTypes
      } else if (flag === 'YW_FLOW_STATE') {
        dict = this.apiDeployStates
      }else if (flag === 'TXPE_OF_CAMOUS') {
        dict = this.schoolTypes
      }
      for (var i in dict) {
        if (dict[i].code === code) {
          return dict[i].name
        }
      }
    },
    // //操作日志
    // handleLog(data){
    //   this.dialogVisibleLog=true
    //   this.logQuery.id=data.id;
    //   this.geLogList();
    // },
    //  geLogList(){
    //   this.logLoading=true
    //   getLogByDevelopment(this.logQuery).then(response=>{
    //     this.logLoading=false
    //     this.logList=response.data.records;
    //     this.logTotal=parseInt(response.data.total)
    //   }).catch(error=>{
    //     this.logLoading=false
    //   })
    // },
    // //节点过滤
    // handleNodeClick(data){
    //   if(data.level==="1"){
    //     this.listQuery.themeId=data.id;
    //     this.loadList();
    //   }
    // },
    // //节点过滤
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    // handleFilter() {
    //   this.listQuery.page = 1
    //   this.loadList()
    // },
    loadList() {
      
      this.listLoading = true
      this.listQuery.state=2
      districtPage(this.listQuery).then(response => {
        this.listLoading = false
        this.list = response.data.records
        
        this.total = parseInt(response.data.total)
      }).catch(response => {
        this.listLoading = false
      })
//       this.list=[
//   {
//     "id": "1074999715231170560",
//     "name": "22年学校装备填报",
//     "beginDate": "2023-02-14 10:25:05",
//     "endDate": "2023-02-14 10:25:05",
//     "createTimeTi": "2023-02-14 10:25:05",
//     "createUserId": "13",
//     "createUserName": "用户1",
//     "createDict": "学校1",
//     "state": "1"
    
//   }
// ]
      this.listLoading = false
      this.total = parseInt(1)
    },
    // handleAdd() {
    //   this.$router.push({ path: './arrange/arrangeAdd' })
    // },
    handleEdit(row) {
      this.code=row.id
       this.dialogVisibleEI=true
        this.$nextTick(() => {
         this.$refs.examineindex
        })
    },
    handleSubmitTest(row) {
      this.code=row.id
      this.dialogVisibleTask=true
        this.$nextTick(() => {
         this.$refs.taskindex
        })
    },
    // handleRelease(row) {
    //   MessageBox.confirm('确定发布吗', '确定', {
    //     confirmButtonText: '确定',
    //     cancelButtonText: '取消',
    //     cancelButtonClass: 'qx',
    //     type: 'warning'
    //   }).then(() => {
    //     const loading = this.$loading({
    //       lock: true,
    //       text: 'Loading',
    //       spinner: 'el-icon-loading',
    //       background: 'rgba(0, 0, 0, 0.7)'
    //     })

    //     release(row.id).then(response => {
    //       Message({
    //         message: '操作成功',
    //         type: 'success',
    //         duration: 5 * 1000
    //       })
    //       loading.close()
    //       this.handleFilter()
    //     }).catch(function() {
    //       loading.close()
    //     })
    //   })
    // },
    //树型结构
    getTableTreeList(){
      this.treeLoading=true;
      this.treeList=[{
        name:"教育装备填报",children:[
          {name:"表1 学校基本情况统计表",children:[]},
          {name:"表2 实验室管理人员情况统计表",children:[]},
          {name:"表3 实验室及功能教室用房情况统计表",children:[]},
          {name:"表4 教学仪器情况统计表",children:[]},
          {name:"表5 教学设备情况统计表",children:[]},
          {name:"表6 理科实验开出情况统计表",children:[]},
          {name:"表7 图书管理人员情况统计表",children:[]}
        ]
      }]
      this.treeLoading=false;
      // getDeployTreeList({itemId:this.$getCookie('projectId')}).then(response=>{
      //   this.treeList=response.data;
      //   this.treeLoading=false;
      // }).catch(error=>{
      //   this.treeLoading=false;
      // })
    },
    // handleRevoke(row) {
    //   MessageBox.confirm('确定撤销吗', '确定', {
    //     confirmButtonText: '确定',
    //     cancelButtonText: '取消',
    //     cancelButtonClass: 'qx',
    //     type: 'warning'
    //   }).then(() => {
    //     const loading = this.$loading({
    //       lock: true,
    //       text: 'Loading',
    //       spinner: 'el-icon-loading',
    //       background: 'rgba(0, 0, 0, 0.7)'
    //     })

    //     revoke(row.id).then(response => {
    //       Message({
    //         message: '操作成功',
    //         type: 'success',
    //         duration: 5 * 1000
    //       })
    //       loading.close()
    //       this.handleFilter()
    //     }).catch(function() {
    //       loading.close()
    //     })
    //   })
    // }
  }
}
</script>
<style lang="less" scoped>
.app-container{
  padding:10px 20px;
  height: 100%;
}
.filter-container {
  padding-bottom: 10px;

  .filter-item {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 10px;
    margin-right: 10px;
  }
  .date-filter-item{
    vertical-align: middle;
    margin-bottom: 10px;
    margin-right: 10px;
  }
}
.pagination-container{
  margin-top:0 !important;
  padding: 10px 16px !important;
}
#app-contain{
    height:100%;
    width:100%;
  }
</style>
